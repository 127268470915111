import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { Copyright } from "../common-components/CopyRight";
import { Box } from "@mui/material";
import SignInForm from "./SignInForm";
import { useEffect, useState } from "react";
import { rememberThisDevice } from "../../types/User";

export default function SignIn() {
  const [keepSession, setKeepSession] = useState(false);
  useEffect(() => {
    setKeepSession(localStorage.getItem(rememberThisDevice) === "true");
  }, []);
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          minWidth: "100%",
          minHeight: "90vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <SignInForm keepSession />
      </Box>
      <Copyright />
    </Container>
  );
}
