import { Box, Typography } from "@mui/material";

export function PasswordRequirements(props: any) {
  return (
    <Box sx={{ minWidth: "100%", mt: 1 }}>
      <Typography component="h1" variant="body1">
        Requisitos
      </Typography>
      <Typography component="h1" variant="body2">
        Al menos 8 caracteres
      </Typography>
      <Typography component="h1" variant="body2">
        Al menos una minuscula y una mayuscula
      </Typography>
      <Typography component="h1" variant="body2">
        Al menos un numero
      </Typography>
      <Typography component="h1" variant="body2">
        Al menos un caracter especial
      </Typography>
    </Box>
  );
}
