import * as React from "react";
import Box from "@mui/material/Box";
import { useState } from "react";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ResetPasswordForm from "./ResetPasswordForm";
import { Copyright } from "@mui/icons-material";
import { Container, CssBaseline } from "@mui/material";

export default function ResetPassword() {
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [userName, setUserName] = useState<string | undefined>(undefined);

  const handleSubmiForgetPassword = (userName?: string) => {
    setUserName(userName);
    setShowResetPassword(true);
  };

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          minWidth: "100%",
          minHeight: "90vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {showResetPassword ? (
          <ResetPasswordForm userName={userName!} />
        ) : (
          <Box>
            <ForgotPasswordForm
              handleSubmiForgetPassword={handleSubmiForgetPassword}
            />
          </Box>
        )}
      </Box>
      <Copyright />
    </Container>
  );
}
