export interface User {
  id: string;
  userCredentials: UserCredentials;
  userInfo?: UserInfo;
  practice?: Practice;
  businessInformation?: TaxInformation;
}

export interface UserCredentials {
  email: string;
  emailVerified: string;
}
export interface UserInfo {
  name: string;
  phoneNumbers: PhoneNumber[];
}
export interface Practice {
  type: string;
  address: Address;
  institution?: string;
  number: PhoneNumber;
}

export interface TaxInformation {
  type: TypeOfFiscalPerson;
  rfc: string;
  bussines: BusinessInformation;
  person: SelfEmployecInformation;
}

export interface BusinessInformation {
  businessLegalName: string;
}

export interface SelfEmployecInformation {}
export interface PhoneNumber {
  number: string;
  type: PhoneNumberType;
  main: boolean;
}
export interface Address {
  calle: string;
  numeroExterior: number;
  numeroInterior: string;
  colonia: string;
  codigoPostal: number;
  estado: StateEnum;
}

export enum PhoneNumberType {
  CELLPHONE,
  LANDLINE,
  WORK,
  OTHER,
}
export enum StateEnum {
  Chihuahua,
  Quintana_Roo,
  Yucatan,
  Nuevo_Leon,
  Ciudad_de_Mexico,
}
export enum TypeOfFiscalPerson {
  fisica,
  moral,
}
export const rememberThisDevice = "rememberThisDevice";
