import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import {
	FormContainer,
	TextFieldElement,
	PasswordRepeatElement,
	FormErrorProvider,
} from "react-hook-form-mui";
import { Skeleton } from "@mui/material";
import { PatternPasswordElement } from "../common-components/PatternPasswordElement";
import { PasswordRequirements } from "../common-components/PasswordRequirements";
import { forgotPasswordSubmit } from "../../services/auth/AuthService";
import { useNavigate } from "react-router-dom";
import { commonTexts } from "../commons/TextsConstants";
import { resetPasswordTexts } from "./TextsConstants";

interface Props {
	userName?: string;
}

interface Values {
	code: string;
	password: string;
}

export default function ResetPasswordForm(props: Props) {
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);

	const handleResetPassword = (values: Values) => {
		setIsLoading(true);
		forgotPasswordSubmit(
			props.userName || "wrong@mail.com",
			values.password,
			values.code
		)
			.then((res) => {
				console.log(res);
				navigate(`/signIn`);
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	return isLoading ? (
		<Skeleton height={600} />
	) : (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
				<AccountCircleIcon />
			</Avatar>
			<Typography component="h1" variant="h5">
				{resetPasswordTexts.form.formTitle}
			</Typography>
			<FormErrorProvider
				onError={(error) => {
					console.log("you can hook your own error message", error);
					if (error.type === "required") {
						return commonTexts.validationErrors.fieldNull;
					}
					if (error.type === "pattern") {
						return commonTexts.validationErrors.invalidPassword;
					}
					if (error.type === "validate" && error.message?.includes("match")) {
						return commonTexts.validationErrors.passwordDontMatch;
					}
					return error?.message;
				}}
			>
				<FormContainer
					onSuccess={(values) => {
						handleResetPassword(values as Values);
					}}
					FormProps={{
						autoComplete: "new-password",
					}}
				>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextFieldElement
								fullWidth
								id="code"
								label={commonTexts.form.codeLabel}
								name="code"
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<PatternPasswordElement
								fullWidth
								name="password"
								label={commonTexts.form.passwordLabel}
								type="password"
								id="password"
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<PasswordRepeatElement
								fullWidth
								passwordFieldName={"password"}
								name={"password-repeat"}
								label={commonTexts.form.repeatPasswordLabel}
								type="password"
								id="password-repeat"
								required
							/>
						</Grid>
					</Grid>
					<PasswordRequirements />
					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						{resetPasswordTexts.form.resetPasswordButton}
					</Button>
				</FormContainer>
			</FormErrorProvider>
			<Grid container justifyContent="center">
				<Grid item>
					<Button variant="text" onClick={() => navigate(`/signIn`)}>
						{commonTexts.form.backToLoginButton}
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
}
