import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MessageContent } from "../types/MessageContent";

type messageState = MessageContent | null;

// Define the initial state using that type
const initialState = null as messageState;

export const notificationSlice = createSlice({
  name: "messageContent",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setMessageContent: (
      state,
      { payload: { title, text, severity } }: PayloadAction<MessageContent>
    ) => {
      return { title, text, severity };
    },
    clearMessageContent: (state) => {
      return null;
    },
  },
});

export default notificationSlice.reducer;
export const { setMessageContent, clearMessageContent } =
  notificationSlice.actions;
