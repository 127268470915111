import { Alert, AlertTitle, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { useAppDispatch } from "../../store/Hooks";
import { clearMessageContent } from "../../store/notificationSlice";

const MessageSnackBar = () => {
  const dispatch = useAppDispatch();
  const [openSnackbar, setOpenSnackbar] = useState(true);
  const content = useSelector((state: RootState) => state.messageContent);
  useEffect(() => {
    setOpenSnackbar(true);
  }, [content]);

  return content ? (
    <Snackbar
      id="snackbarAuthListener"
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={openSnackbar}
      autoHideDuration={10000}
      onClose={() => {
        setOpenSnackbar(false);
        dispatch(clearMessageContent());
      }}
    >
      <Alert severity={content.severity}>
        <AlertTitle>{content.title}</AlertTitle>
        {content.text}
      </Alert>
    </Snackbar>
  ) : (
    <></>
  );
};
export default MessageSnackBar;
