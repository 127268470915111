import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import LockResetIcon from "@mui/icons-material/LockReset";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import {
  FormErrorProvider,
  FormContainer,
  TextFieldElement,
} from "react-hook-form-mui";
import { forgotPassword } from "../../services/auth/AuthService";
import { forgotPasswordTexts } from "./TextsConstants";
import { commonTexts } from "../commons/TextsConstants";
import { useNavigate } from "react-router-dom";

interface Props {
  handleSubmiForgetPassword: (username?: string) => void;
}

export default function ForgotPasswordForm(props: Props) {
  const navigate = useNavigate();

  function handleForgetPassword(username: string) {
    forgotPassword(username)
      .then(() => {
        props.handleSubmiForgetPassword(username);
      })
      .catch((err) => {
        props.handleSubmiForgetPassword(undefined);
      });
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockResetIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ m: 1 }}>
          {forgotPasswordTexts.form.formTitle}
        </Typography>
        <Typography component="h1" variant="body1" align="center" sx={{ m: 1 }}>
          {forgotPasswordTexts.form.introduceMail}
        </Typography>
      </Box>
      <FormErrorProvider
        onError={(error) => {
          console.log("you can hook your own error message", error);
          if (error.type === "required") {
            return commonTexts.validationErrors.fieldNull;
          }
          if (error.type === "pattern" && error.message?.includes("email")) {
            return commonTexts.validationErrors.invalidEmail;
          }
          return error?.message;
        }}
      >
        <FormContainer
          defaultValues={{
            username: "",
            password: "",
          }}
          onSuccess={(values) => {
            handleForgetPassword(values.username);
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldElement
                fullWidth
                id="username"
                label={commonTexts.form.emailLabel}
                name="username"
                autoComplete="email"
                type="email"
                required
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {forgotPasswordTexts.form.resetPasswordButton}
          </Button>
        </FormContainer>
      </FormErrorProvider>
      <Grid container>
        <Grid item xs>
          <Button variant="text" onClick={() => navigate(`/signIn`)}>
            {commonTexts.form.backToLoginButton}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
