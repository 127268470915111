import { constant } from "cypress/types/lodash";
import { CatTipoSanguineo } from "../../models";
import { CatGenero } from "../../models";
import { CatSexo } from "../../models";

export const commonTexts = {
	form: {
		passwordLabel: "Contraseña",
		repeatPasswordLabel: "Reintrodusca su contraseña",
		emailLabel: "Correo electronico",
		codeLabel: "Codigo",
		backToLoginButton: "Regresar a iniciar secion",
		oldPassword: "Contraseña actual",
		newPassword: "Nueva contraseña",
	},
	validationErrors: {
		invalidEmail: "Favor de introducir un correo valido",
		fieldNull: "Este campo es obligatorio",
		invalidPassword:
			"Asegurese que la contraseña respete los criterios de seguridad",
		passwordDontMatch: "Las contraseñas deben ser identicas",
	},
};

export const authErrors = {
	CodeMismatchException: {
		title: "Codigo incorrecto",
		text: "Favor de volver a intentarlo",
	},
	LimitExceededException: {
		title: "Numero de intentos exedido",
		text: "Favor de intentarlo mas tarde",
	},
	UnhandledError: {
		title: "Problema inesperado",
		text: "Se encontro un problem inesperado, favor de comunicarse con nostros o intentarlo mas tarde",
	},
	UserNotFoundException: {
		title: "Usuario o contraseña incorrectos",
		text: "Porfavor verifique sus datos y vuelva a intenrarlo",
	},
	NotAuthorizedException: {
		title: "Usuario o contraseña incorrectos",
		text: "Porfavor verifique sus datos y vuelva a intenrarlo",
	},
};

export const bloodType = [
	{key:CatTipoSanguineo.AN, name:"A-"},
	{key:CatTipoSanguineo.AP, name:"A+"},
	{key:CatTipoSanguineo.BN, name:"B-"},
	{key:CatTipoSanguineo.BP, name:"B+"},
	{key:CatTipoSanguineo.ABN, name:"AB-"},
	{key:CatTipoSanguineo.ABP, name:"AB+"},
	{key:CatTipoSanguineo.OP, name:"O+"},
	{key:CatTipoSanguineo.ON, name:"O-"},
	{key:CatTipoSanguineo.UNKNOWN, name:"No lo sabe"}

]
export const sex = [
	{ key:CatSexo.HOMBRE_1, name:"Masculino"},
	{ key:CatSexo.MUJER_2, name:"Femenino"},
	{ key:CatSexo.INTERSEXUAL_3, name:"No binario"},
]

export const gender = [ 
	{key:CatGenero.MASCULINO_1, name:"Masculino"},
	{key:CatGenero.FEMENINO_2, name:"Femenino"},
	{key:CatGenero.TRANSGENERO_3, name:"Transgenero"},
	{key:CatGenero.TRANSEXUAL_4, name:"Transexual"},
	{key:CatGenero.TRASVESTI_5, name:"Travesti"},
	{key:CatGenero.INTERSEXUAL_6, name:"Intersexual"},
	{key:CatGenero.OTRO_88, name:"Otro"},
	{key:CatGenero.NO_ESPECIFICADO_0, name:"No especificado"},
]

export const ethnicity = [
	{ key:"INDIGENA", name:"Indigena"},
	{ key:"AFROMEXICANO", name:"Afromexicano"},
	{ key:"ASIATICO", name:"Asiatico"},
	{ key:"OTRO", name:"Otro"}
]

export const states = [
	{ key:"AGS", name:"Aguascalientes"},
	{ key:"BCN", name:"Baja California Norte"},
	{ key:"BCS", name:"Baja California Sur"},
	{key: "CAM", name:"Campeche"},
	{key: "CHP", name: "Chiapas"},
	{key:"CHI", name:"Chihuahua"},
	{key:"DIF", name:"Ciudad de Mexico"},
	{key:"COA", name:"Coahuila"},
	{key:"COL", name:"Colima"},
	{key:"DUR", name:"Durango"},
	{key:"GTO", name:"Guanajuato"},
	{key:"GRO", name:"Guerrero"},
	{key:"HGO", name:"Hidalgo"},
	{key:"JAL", name:"Jalisco"},
	{key:"MEX", name:"Estado de Mexico"},
	{key:"MIC", name:"Michoacan"},
	{key:"MOR", name:"Morelia"},
	{key:"NAY", name:"Nayarit"},
	{key:"NLE", name:"Nuevo Leon"},
	{key:"OAX", name:"Oaxaca"},
	{key:"PUE", name:"Puebla"},
	{key:"QRO", name:"Queretaro"},
	{key:"ROO", name:"Quintana Roo"},
	{key:"SLP", name:"San Luis Potosi"},
	{key:"SIN", name:"Sinaloa"},
	{key:"SON", name:"Sonora"},
	{key:"TAB", name:"Tabasco"},
	{key:"TAM", name:"Tamaulipas"},
	{key:"TXL", name:"Tlaxcala"},
	{key:"VER", name:"Veracruz"},
	{key:"YUC", name:"Yucatan"},
	{key:"ZAC", name:"Zacatecas"},
	{key:"OTH",name:"Extanjero"}
]