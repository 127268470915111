export const signUpTexts = {
  form: {
    signUpButton: "Subscribirte",
    signUpTitle: "Crear una cuenta",
  },
  signUpErrors: {
    UsernameExistsException: {
      title:
        "Esta cuenta de correo ya se encuentra registrada en nuestro sistema",
      text: "Itenta con otro correo o inicie secion, si piensa que esto es un error no dude en contactarnos",
    },
  },
};

export const validateEmailFormTexts = {
  form: {
    validateEmail: "Confirmar su correo",
    messageMailSent:
      "Favor de introducir el codigo que enviamos a su correo para confirmar su inscripcion",
    sendCode: "Enviar codigo",
    reSendCode: "Reenviar Codigo",
    messageMailResent: "Hemos reenviado un codigo a su correo",
  },
};
