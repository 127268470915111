import { Hub } from "aws-amplify";
import {  useState } from "react";
import { authErrors } from "../../components/commons/TextsConstants";
import {
  forgotPasswordTexts,
  resetPasswordTexts,
} from "../../components/forgot-password/TextsConstants";
import { useAppDispatch } from "../../store/Hooks";
import { MessageContent } from "../../types/MessageContent";

const handledErros = [
  "CodeMismatchException",
  "LimitExceededException",
  "UserNotFoundException",
  "NotAuthorizedException",
];
const parseError = (code: string) => {
  return handledErros.includes(code)
    ? (authErrors as any)[code]
    : (authErrors as any)["UnhandledError"];
};

export default function useSubscribeToAuthMessages() {
  const dispatch = useAppDispatch();
  const [messageContent, setMessageContent] = useState<MessageContent | null>(
    null
  );

  Hub.listen("auth", ({ payload: { event, data } }) => {
    console.log(event);
    console.log(data);

    if (event.match("failure")) {
      switch (event) {
        case "forgotPassword_failure":
          if (data.code === "UserNotFoundException") {
            setMessageContent({
              ...forgotPasswordTexts.mailSent,
              severity: "success",
            });
          } else {
            setMessageContent({
              ...parseError(data.code),
              severity: "error",
            });
          }
          break;
        case "forgotPasswordSubmit_failure":
          if (data.code === "UserNotFoundException") {
            setMessageContent({
              ...authErrors.CodeMismatchException,
              severity: "error",
            });
          } else {
            setMessageContent({
              ...parseError(data.code),
              severity: "error",
            });
          }
          break;
        default:
          setMessageContent({
            ...parseError(data.code),
            severity: "error",
          });
      }
    } else {
      switch (event) {
        case "signUp":
          console.error("user sign up");
          break;
        case "forgotPassword":
          setMessageContent({
            ...forgotPasswordTexts.mailSent,
            severity: "success",
          });
          break;
        case "forgotPasswordSubmit":
          setMessageContent({
            ...resetPasswordTexts.resetPasswordSuccess,
            severity: "success",
          });
          break;
      }
    }
  });
  return messageContent;
}
