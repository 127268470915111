import * as React from "react";
import Box from "@mui/material/Box";
import { confirmSignUp } from "../../services/auth/AuthService";
import { useState } from "react";
import SignUpForm from "./SignUpForm";
import ValiateEmailForm from "./ValidateEmailForm";
import { Copyright } from "@mui/icons-material";
import { Container, CssBaseline } from "@mui/material";

export default function SignUp() {
  const [showValidateEmail, setShowValidateEmail] = useState(false);
  const [userName, setUserName] = useState<string | null>(null);
  const [userSub, setUserSub] = useState<string | null>(null);

  const handleSignUpResponse = async (username: string, userSub: string) => {
    setUserName(username);
    setUserSub(userSub);
    setShowValidateEmail(true);
  };

  const handleValidateEmail = async (emailCode: string) => {
    if (userName !== null && userSub !== null) {
      confirmSignUp({ userName, emailCode }).catch();
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          minWidth: "100%",
          minHeight: "90vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {!showValidateEmail ? (
          <Box>
            <SignUpForm handleAuthenticateResponse={handleSignUpResponse} />
          </Box>
        ) : (
          <ValiateEmailForm
            handleValidateEmail={handleValidateEmail}
            userNanme={userName!}
          />
        )}
      </Box>
      <Copyright />
    </Container>
  );
}
