import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import { User, UserCredentials } from "../types/User";

type UserState = User | null;

// Define the initial state using that type
const initialState = null as UserState;

export const authSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { email, id, emailVerified },
      }: PayloadAction<{ email: string; id: string; emailVerified: string }>
    ) => {
      return { ...state, id: id, userCredentials: { email, emailVerified } };
    },
    setUserInfo: (state, { payload: user }: PayloadAction<User>) => {
      return { ...user, userCredential: state!.userCredentials };
    },
    clearCredentials: () => {
      return null;
    },
  },
});

export default authSlice.reducer;
export const { setCredentials, clearCredentials } = authSlice.actions;
