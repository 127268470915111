import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Avatar, Grid, Skeleton, Typography } from "@mui/material";
import { useState } from "react";
import { signIn } from "../../services/auth/AuthService";
import {
  FormErrorProvider,
  FormContainer,
  TextFieldElement,
  PasswordElement,
  CheckboxElement,
} from "react-hook-form-mui";
import { rememberThisDevice } from "../../types/User";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { commonTexts } from "../commons/TextsConstants";
import { signInTexts } from "./TextsConstants";
import { useNavigate } from "react-router-dom";

interface Props {
  keepSession: boolean;
}

export default function SignInForm(props: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  function handleSingIn(
    username: string,
    password: string,
    rememberMe: boolean
  ) {
    signIn({ username, password })
      .then((user) => {
        localStorage.setItem(rememberThisDevice, rememberMe.toString());
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  return isLoading ? (
    <Skeleton height={420} sx={{ minWidth: "100%" }} />
  ) : (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ m: 1 }}>
          Sign in
        </Typography>
      </Box>
      <FormErrorProvider
        onError={(error) => {
          console.log("you can hook your own error message", error);
          if (error.type === "required") {
            return commonTexts.validationErrors.fieldNull;
          }
          if (error.type === "minLength") {
            return "not long";
          }
          if (error.type === "pattern" && error.message?.includes("email")) {
            return commonTexts.validationErrors.invalidEmail;
          }
          if (error.type === "pattern") {
            return commonTexts.validationErrors.invalidPassword;
          }
          if (error.type === "validate" && error.message?.includes("match")) {
            return commonTexts.validationErrors.passwordDontMatch;
          }
          return error?.message;
        }}
      >
        <FormContainer
          defaultValues={{
            rememberMe: props.keepSession,
            username: "",
            password: "",
          }}
          onSuccess={(values) => {
            setIsLoading(true);
            handleSingIn(values.username, values.password, values.rememberMe);
          }}
          FormProps={{
            autoComplete: "current-password",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldElement
                fullWidth
                id="username"
                label={commonTexts.form.emailLabel}
                name="username"
                autoComplete="email"
                type="email"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordElement
                fullWidth
                name="password"
                label={commonTexts.form.passwordLabel}
                id="password"
                required
              />
            </Grid>
          </Grid>
          <CheckboxElement
            label={signInTexts.form.rememberMeLabel}
            name="rememberMe"
            id="rememberMe"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {signInTexts.form.signInButton}
          </Button>
        </FormContainer>
      </FormErrorProvider>
      <Grid container>
        <Grid item xs>
          <Button variant="text" onClick={() => navigate(`/forgotPassword`)}>
            {signInTexts.form.forgotPasswordButton}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="text" onClick={() => navigate(`/signUp`)}>
            {signInTexts.form.registerButton}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
