import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, IconButton } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/Hooks";
import { signOut } from "../../services/auth/AuthService";

const IconMenu = () => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const dispatch = useAppDispatch();

	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleLogout = () => {
		signOut();
	};

	const handleClose = (id: "logout" | "profile") => {
		if (id === "logout") {
			handleLogout();
		}
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				color="inherit"
				sx={{ p: 0.5 }}
				id="basic-button"
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
			>
				<Avatar alt="My Avatar">M</Avatar>
			</IconButton>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				<MenuItem onClick={() => handleClose("profile")}>Profile</MenuItem>
				<MenuItem onClick={() => handleClose("logout")}>Logout</MenuItem>
			</Menu>
		</>
	);
};
export default IconMenu;
