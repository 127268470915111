import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import Typography from "@mui/material/Typography";
import { Alert, Skeleton, Snackbar } from "@mui/material";
import {
	FormContainer,
	FormErrorProvider,
	TextFieldElement,
} from "react-hook-form-mui";
import { reSendCode } from "../../services/auth/AuthService";
import { useState } from "react";
import { validateEmailFormTexts } from "./TextsConstants";
import { commonTexts } from "../commons/TextsConstants";

interface Props {
	handleValidateEmail: (code: string) => void;
	userNanme: string;
}

export default function ValiateEmailForm(props: Props) {
	const [isLoading, setIsLoading] = useState(false);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const handleSubmit = (code: string) => {
		setIsLoading(true);
		props.handleValidateEmail(code);
	};

	function resend(): void {
		reSendCode(props.userNanme)
			.then(() => setOpenSnackbar(true))
			.catch();
	}

	return isLoading ? (
		<Skeleton height={420} />
	) : (
		<Box>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={() => {
					setOpenSnackbar(false);
				}}
				id="validateEmailFormSnackbar"
			>
				<Alert severity="success" sx={{ width: "100%" }}>
					{validateEmailFormTexts.form.messageMailResent}
				</Alert>
			</Snackbar>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
					<MarkEmailReadIcon />
				</Avatar>
			</Box>
			<Typography component="h1" variant="h5" sx={{ p: 2 }} align="center">
				{validateEmailFormTexts.form.validateEmail}
			</Typography>
			<Typography component="h1" variant="body1" align="center">
				{validateEmailFormTexts.form.messageMailSent}
			</Typography>
			<FormErrorProvider
				onError={(error) => {
					console.log("you can hook your own error message", error);
					if (error.type === "required") {
						return commonTexts.validationErrors.fieldNull;
					}
					return error?.message;
				}}
			>
				<FormContainer
					onSuccess={(values) => {
						handleSubmit(values.emailCode);
					}}
					FormProps={{
						autoComplete: "one-time-code",
					}}
				>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextFieldElement
								required
								fullWidth
								name="emailCode"
								label={commonTexts.form.codeLabel}
								id="emailCode"
							/>
						</Grid>
					</Grid>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						{validateEmailFormTexts.form.sendCode}
					</Button>
				</FormContainer>
			</FormErrorProvider>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Button variant="text" onClick={resend}>
					{validateEmailFormTexts.form.reSendCode}
				</Button>
			</Box>
		</Box>
	);
}
