import { Box } from "@mui/material";
interface Props {
  children: string | JSX.Element | JSX.Element[];
}
export const HoverBox = ({ children }: Props) => {
  return (
    <Box
      sx={{
        pt: 2,
        pr: 2,
        pl: 2,
        pb: 1,
        border: "1px solid",
        borderColor: "#c9c9c9",
        borderRadius: "16px",
        width: "100%",
        "&:hover": {
          border: "1px solid black",
        },
      }}
    >
      {children}
    </Box>
  );
};
