import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  FormErrorProvider,
  FormContainer,
  TextFieldElement,
  DatePickerElement,
  SelectElement,
  CheckboxButtonGroup,
  RadioButtonGroup,
} from "react-hook-form-mui";
import {
  bloodType,
  commonTexts,
  ethnicity,
  gender,
  sex,
  states,
} from "../commons/TextsConstants";
import { patientTexts } from "./TextConstants";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/es-mx";
import { HoverBox } from "../common-components/core/HoverBox";
import { CatPais, Maritalstatus } from "../../models";
import { useState } from "react";
import { theme } from "../dashboard/Theme";
import BoltIcon from "@mui/icons-material/Bolt";

export const NewPatient = () => {
  const [curpState, setCurpState] = useState("");
  const [generateCurpDisabled, setGenerateCurpDisabled] = useState(true);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryOfBirth, setCountryOfBirth] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [sexState, setSexState] = useState("");

  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const generateCurp = () => {
    setCurpState("ABCS");
  };
  return (
    <Container maxWidth="sm">
      <FormErrorProvider
        onError={(error) => {
          console.log("you can hook your own error message", error);
          if (error.type === "required") {
            return commonTexts.validationErrors.fieldNull;
          }
          if (error.type === "pattern" && error.message?.includes("email")) {
            return commonTexts.validationErrors.invalidEmail;
          }
          return error?.message;
        }}
      >
        <FormContainer
          defaultValues={{
            birthCountry: "Mexico",
            sex: "HOMBRE_1",
            curp: curpState,
          }}
          onSuccess={(values) => {
            console.log(values);
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              component="h5"
              variant="h5"
              sx={{ m: 1, alignSelf: "center" }}
            >
              {patientTexts.form.formTitle}
            </Typography>
            <Typography component="h1" variant="body1" sx={{ mb: 1 }}>
              {patientTexts.form.generalInformation}
            </Typography>
          </Box>
          <Grid container spacing={2} columns={{ xs: 6, md: 12 }}>
            <Grid item xs={6}>
              <TextFieldElement
                fullWidth
                id="name"
                label={patientTexts.name}
                name="name"
                type="text"
                required
                onChange={(event) => setName(event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldElement
                fullWidth
                id="lastName"
                label={patientTexts.lastName}
                name="lastName"
                type="text"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <SelectElement
                fullWidth
                label={patientTexts.sex}
                name="sex"
                required
                options={sex.map(({ key, name }) => {
                  return { id: key, label: name };
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="es-mx"
              >
                <DatePickerElement
                  label={patientTexts.birthday}
                  name="birthday"
                  required
                  disableFuture
                  sx={{ width: "100%" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <SelectElement
                fullWidth
                label={patientTexts.birthContry}
                name="birthCountry"
                required
                options={Object.values(CatPais)
                  .map((value) => {
                    return value.replaceAll("_", " ");
                  })
                  .map((value) => {
                    return { id: value, label: value };
                  })}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectElement
                fullWidth
                required
                label={patientTexts.birthState}
                name="basic"
                options={states.map(({ key, name }) => {
                  return { id: key, label: name };
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldElement
                fullWidth
                id="curp"
                label={patientTexts.curp}
                name="curp"
                type="text"
                required
                InputProps={{
                  value: curpState,
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={generateCurp}
                        disabled={generateCurpDisabled}
                      >
                        <BoltIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Typography component="h1" variant="body1" sx={{ mt: 2, mb: 1 }}>
            {patientTexts.form.contactInformation}
          </Typography>
          <Grid container spacing={2} columns={{ xs: 6, md: 12 }}>
            <Grid item xs={6}>
              <TextFieldElement
                fullWidth
                id="number"
                label={patientTexts.number}
                name="number"
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldElement
                fullWidth
                id="email"
                label={patientTexts.email}
                name="email"
                type="email"
              />
            </Grid>
          </Grid>
          <Typography component="h1" variant="body1" sx={{ mt: 2, mb: 1 }}>
            {patientTexts.form.complementaryInformation}
          </Typography>
          <Grid container spacing={2} columns={{ xs: 6, md: 12 }}>
            <Grid item xs={6}>
              <TextFieldElement
                fullWidth
                id="education"
                label={patientTexts.education}
                name="education"
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <SelectElement
                fullWidth
                label={patientTexts.maritalStatus}
                name="maritalStatus"
                options={Object.values(Maritalstatus)
                  .map((value) => {
                    return value.replaceAll("_", " ").toLocaleLowerCase();
                  })
                  .map((value) => {
                    return { id: value, label: value };
                  })}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectElement
                fullWidth
                label={patientTexts.bloodType}
                name="bloodType"
                options={bloodType.map(({ key, name }) => {
                  return { id: key, label: name };
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldElement
                fullWidth
                id="religion"
                label={patientTexts.religion}
                name="religion"
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldElement
                fullWidth
                id="ocupation"
                label={patientTexts.ocupation}
                name="ocupation"
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldElement
                fullWidth
                id="activity"
                label={patientTexts.activity}
                name="activity"
                type="text"
              />
            </Grid>
            <Grid item xs={6} md={12}>
              <HoverBox>
                <CheckboxButtonGroup
                  row={isSmUp}
                  label={patientTexts.ethnicity}
                  name="ethnicity"
                  options={ethnicity.map(({ key, name }) => {
                    return { id: key, label: name };
                  })}
                />
              </HoverBox>
            </Grid>
            <Grid item xs={6} md={12}>
              <HoverBox>
                <RadioButtonGroup
                  row={isSmUp}
                  label={patientTexts.gender}
                  name="gender"
                  options={gender.map(({ key, name }) => {
                    return { id: key, label: name };
                  })}
                />
              </HoverBox>
            </Grid>
          </Grid>
          <Typography component="h1" variant="body1" sx={{ mt: 2, mb: 1 }}>
            {patientTexts.form.address}
          </Typography>
          <Grid container spacing={2} columns={{ xs: 6, md: 12 }}>
            <Grid item xs={6}>
              <TextFieldElement
                fullWidth
                id="street"
                label={patientTexts.street}
                name="street"
                type="text"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextFieldElement
                fullWidth
                id="streetNumber"
                label={patientTexts.streetNumber}
                name="streetNumber"
                type="number"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextFieldElement
                fullWidth
                id="zipCode"
                label={patientTexts.zipCode}
                name="zipCode"
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldElement
                fullWidth
                id="county"
                label={patientTexts.county}
                name="county"
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldElement
                fullWidth
                id="city"
                label={patientTexts.city}
                name="city"
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <SelectElement
                fullWidth
                label={patientTexts.state}
                name="state"
                options={states.map(({ key, name }) => {
                  return { id: key, label: name };
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectElement
                fullWidth
                label={patientTexts.country}
                name="country"
                options={Object.values(CatPais)
                  .map((value) => {
                    return value.replaceAll("_", " ");
                  })
                  .map((value) => {
                    return { id: value, label: value };
                  })}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {patientTexts.form.save}
          </Button>
        </FormContainer>
      </FormErrorProvider>
    </Container>
  );
};
