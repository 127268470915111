import { PasswordElement } from "react-hook-form-mui";

export function PatternPasswordElement(props: any) {
  return (
    <PasswordElement
      {...props}
      validation={{
        pattern:
          /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/i,
      }}
    />
  );
}
