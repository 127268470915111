export const patientTexts = {
    form:{
        formTitle: "Datos del paciente",
        generalInformation: "Datos Generales",
        complementaryInformation: "Informacion Complementaria",
        contactInformation:"Informacion de Contacto",
        address: "Direccion",
        save:"Guardar",
        generateCurp: "Autocompletar"
    },
    name: "Nombre",
    lastName: "Apellidos",
    curp: "CURP",
    birthday: "Fecha de nacimiento",
    birthState: "Entidad de nacimiento",
    birthContry: "Pais de nacimiento",
    gender: "Genero",
    sex: "Sexo",
    ethnicity:"Grupo etnico",
    religion: "Religion",
    ocupation: "Ocupacion",
    education: "Escolaridad",
    maritalStatus: "Estatus marital",
    bloodType: "Tipo sanguineo",
    activity: "Actividades",
    number: "Numero de telefono",
    email:"Correo electronico",
    street: "Calle",
    streetNumber: "Numero",
    zipCode: "Codigo postal",
    city: "Ciudad",
    county: "Colonia",
    state: "Estado",
    country: "Pais"
}