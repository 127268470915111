import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { signUp } from "../../services/auth/AuthService";
import {
	FormContainer,
	TextFieldElement,
	PasswordElement,
	PasswordRepeatElement,
	FormErrorProvider,
} from "react-hook-form-mui";
import { Skeleton } from "@mui/material";
import { signUpTexts } from "./TextsConstants";
import { commonTexts } from "../commons/TextsConstants";
import { useNavigate } from "react-router-dom";

interface Props {
	handleAuthenticateResponse: (username: string, password: string) => void;
}

interface Values {
	username: string;
	password: string;
}

export default function SignUpForm(props: Props) {
	const [isLoading, setIsLoading] = useState(false);
	const [mailError, setMailError] = useState<string | null>(null);
	const [openSnackbar, setOpenSnackbar] = useState(false);

	const navigate = useNavigate();

	const handleSignUp = (values: Values) => {
		setIsLoading(true);
		signUp(values)
			.then(({ user, userSub }) => {
				setIsLoading(false);
				props.handleAuthenticateResponse(user.getUsername(), userSub);
			})
			.catch((response) => {
				switch (response.code) {
					case "UsernameExistsException":
						setMailError("UsernameExistsException");
						setOpenSnackbar(true);
						break;
				}
			})
			.finally(() => setIsLoading(false));
	};

	return isLoading ? (
		<Skeleton height={420} />
	) : (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
				<AccountCircleIcon />
			</Avatar>
			<Typography component="h1" variant="h5">
				{signUpTexts.form.signUpTitle}
			</Typography>
			<FormErrorProvider
				onError={(error) => {
					console.log("you can hook your own error message", error);
					if (error.type === "required") {
						return commonTexts.validationErrors.fieldNull;
					}
					if (error.type === "minLength") {
						return "not long";
					}
					if (error.type === "pattern" && error.message?.includes("email")) {
						return commonTexts.validationErrors.invalidEmail;
					}
					if (error.type === "pattern") {
						return commonTexts.validationErrors.invalidPassword;
					}
					if (error.type === "validate" && error.message?.includes("match")) {
						return commonTexts.validationErrors.passwordDontMatch;
					}
					return error?.message;
				}}
			>
				<FormContainer
					onSuccess={(values) => {
						handleSignUp(values as Values);
					}}
					FormProps={{
						autoComplete: "new-password",
					}}
				>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextFieldElement
								fullWidth
								id="username"
								label={commonTexts.form.emailLabel}
								name="username"
								autoComplete="email"
								type="email"
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<PasswordElement
								fullWidth
								name="password"
								label={commonTexts.form.passwordLabel}
								type="password"
								id="password"
								required
								validation={{
									pattern:
										/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/i,
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<PasswordRepeatElement
								fullWidth
								passwordFieldName={"password"}
								name={"password-repeat"}
								label={commonTexts.form.repeatPasswordLabel}
								type="password"
								id="password-repeat"
								required
							/>
						</Grid>
					</Grid>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						{signUpTexts.form.signUpButton}
					</Button>
				</FormContainer>
			</FormErrorProvider>
			<Grid container justifyContent="center">
				<Grid item>
					<Button variant="text" onClick={() => navigate(`/signIn`)}>
						{commonTexts.form.backToLoginButton}
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
}
