import { SignUpParams } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";

interface SignInParams {
	username: string;
	password: string;
}
interface ConfirmSignUpParams {
	userName: string;
	emailCode: string;
}

export const signUp = async (params: SignUpParams) => {
	const { user, userSub } = await Auth.signUp({
		...params,
		autoSignIn: {
			enabled: true,
		},
	});
	return { user, userSub };
};

export const signIn = async (params: SignInParams) => {
	const { username, password } = params;
	const { user } = await Auth.signIn(username, password);
	return user;
};

export const signOut = async () => await Auth.signOut();

export const reSendCode = async (userName: string) => {
	return await Auth.resendSignUp(userName);
};

export const confirmSignUp = async (params: ConfirmSignUpParams) => {
	return await Auth.confirmSignUp(params.userName, params.emailCode);
};

export const verifySession = async () => {
	return Auth.currentAuthenticatedUser();
};

export const forgotPassword = async (userName: string) => {
	return Auth.forgotPassword(userName);
};

export const forgotPasswordSubmit = async (
	userName: string,
	newPassword: string,
	code: string
) => {
	return Auth.forgotPasswordSubmit(userName, code, newPassword);
};

export const changePassword = async (
	userName: string,
	oldPassword: string,
	newPassword: string
) => {
	return Auth.changePassword(userName, oldPassword, newPassword);
};
