export const schema = {
    "models": {
        "ExpedienteClinico": {
            "name": "ExpedienteClinico",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "consulta": {
                    "name": "consulta",
                    "isArray": true,
                    "type": {
                        "model": "Consulta"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "expedienteClinicoConsultaId"
                        ]
                    }
                },
                "estudios": {
                    "name": "estudios",
                    "isArray": true,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "userExpedienteClinicosId": {
                    "name": "userExpedienteClinicosId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "ExpedienteClinicos",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "gsi-User.expedienteClinicos",
                        "fields": [
                            "userExpedienteClinicosId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Consulta": {
            "name": "Consulta",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "fecha": {
                    "name": "fecha",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "primeraVezAnio": {
                    "name": "primeraVezAnio",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "tipoDeConsulta": {
                    "name": "tipoDeConsulta",
                    "isArray": false,
                    "type": {
                        "enum": "CatTipoConsulta"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "interrogatorio": {
                    "name": "interrogatorio",
                    "isArray": false,
                    "type": {
                        "nonModel": "Interrogatorio"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "examenFisico": {
                    "name": "examenFisico",
                    "isArray": false,
                    "type": {
                        "nonModel": "ExamenFisico"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "receta": {
                    "name": "receta",
                    "isArray": false,
                    "type": {
                        "nonModel": "Receta"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "relacionTemporal": {
                    "name": "relacionTemporal",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "tipoDificultad": {
                    "name": "tipoDificultad",
                    "isArray": false,
                    "type": {
                        "enum": "CatTipoDificultad"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "gradoDificultad": {
                    "name": "gradoDificultad",
                    "isArray": false,
                    "type": {
                        "enum": "CatGradoDificultad"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "origenDificultad": {
                    "name": "origenDificultad",
                    "isArray": false,
                    "type": {
                        "enum": "CatOrigenDificultad"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "descripcionDiagnostico1": {
                    "name": "descripcionDiagnostico1",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "codigoCieDiagnostico": {
                    "name": "codigoCieDiagnostico",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "descripcionDiagnostico2": {
                    "name": "descripcionDiagnostico2",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "primeraVezDiagnostico2": {
                    "name": "primeraVezDiagnostico2",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "codigoCieDiagnostico2": {
                    "name": "codigoCieDiagnostico2",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "descripcionDiagnostico3": {
                    "name": "descripcionDiagnostico3",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "primeraVezDiagnostico3": {
                    "name": "primeraVezDiagnostico3",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "codigoCieDiagnostico3": {
                    "name": "codigoCieDiagnostico3",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "referidoPor": {
                    "name": "referidoPor",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "contraReferido": {
                    "name": "contraReferido",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "expedienteClinicoConsultaId": {
                    "name": "expedienteClinicoConsultaId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Consultas",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "gsi-ExpedienteClinico.consulta",
                        "fields": [
                            "expedienteClinicoConsultaId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "operations": [
                                    "read",
                                    "create"
                                ],
                                "identityClaim": "cognito:username"
                            }
                        ]
                    }
                }
            ]
        },
        "Paciente": {
            "name": "Paciente",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "curp": {
                    "name": "curp",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "nombre": {
                    "name": "nombre",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "primerApellido": {
                    "name": "primerApellido",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "segundoApellido": {
                    "name": "segundoApellido",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "fechaDeNacimiento": {
                    "name": "fechaDeNacimiento",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": true,
                    "attributes": []
                },
                "entidadNacimiento": {
                    "name": "entidadNacimiento",
                    "isArray": false,
                    "type": {
                        "enum": "CatEntidadFederativa"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "sexo": {
                    "name": "sexo",
                    "isArray": false,
                    "type": {
                        "enum": "CatSexo"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "indigena": {
                    "name": "indigena",
                    "isArray": false,
                    "type": {
                        "enum": "CatSiNo"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "afroMexicano": {
                    "name": "afroMexicano",
                    "isArray": false,
                    "type": {
                        "enum": "CatSiNo"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "genero": {
                    "name": "genero",
                    "isArray": false,
                    "type": {
                        "enum": "CatGenero"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "nacionalidadOrigen": {
                    "name": "nacionalidadOrigen",
                    "isArray": false,
                    "type": {
                        "enum": "CatPais"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "domicilio": {
                    "name": "domicilio",
                    "isArray": false,
                    "type": {
                        "nonModel": "Domicilio"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "typoDeSangre": {
                    "name": "typoDeSangre",
                    "isArray": false,
                    "type": {
                        "enum": "CatTipoSanguineo"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "discapacidad": {
                    "name": "discapacidad",
                    "isArray": false,
                    "type": {
                        "enum": "CatDiscapacidad"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "religion": {
                    "name": "religion",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "maritalStatus": {
                    "name": "maritalStatus",
                    "isArray": false,
                    "type": {
                        "enum": "Maritalstatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ocupacion": {
                    "name": "ocupacion",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": false,
                    "attributes": []
                },
                "numero": {
                    "name": "numero",
                    "isArray": false,
                    "type": "AWSPhone",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Pacientes",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "User": {
            "name": "User",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "informacionUsuario": {
                    "name": "informacionUsuario",
                    "isArray": false,
                    "type": {
                        "nonModel": "InformacionUsuario"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "establecimiento": {
                    "name": "establecimiento",
                    "isArray": false,
                    "type": {
                        "nonModel": "Establecimiento"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "informacionFiscal": {
                    "name": "informacionFiscal",
                    "isArray": false,
                    "type": {
                        "nonModel": "InformacionFiscal"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "configuration": {
                    "name": "configuration",
                    "isArray": false,
                    "type": {
                        "nonModel": "AppConfiguration"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "expedienteClinicos": {
                    "name": "expedienteClinicos",
                    "isArray": true,
                    "type": {
                        "model": "ExpedienteClinico"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "userExpedienteClinicosId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Users",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "CatEstablecimiento": {
            "name": "CatEstablecimiento",
            "values": [
                "CONSULTORIO"
            ]
        },
        "CatEntidadFederativa": {
            "name": "CatEntidadFederativa",
            "values": [
                "AGS",
                "BCN",
                "BCS",
                "CAM",
                "CHP",
                "CHI",
                "DIF",
                "COA",
                "COL",
                "DUR",
                "GTO",
                "GRO",
                "HGO",
                "JAL",
                "MEX",
                "MIC",
                "MOR",
                "NAY",
                "NLE",
                "OAX",
                "PUE",
                "QRO",
                "ROO",
                "SLP",
                "SIN",
                "SON",
                "TAB",
                "TAM",
                "TXL",
                "VER",
                "YUC",
                "ZAC"
            ]
        },
        "CatVivienda": {
            "name": "CatVivienda",
            "values": [
                "PRIMARIO",
                "TRABAJO",
                "REFERENCIA"
            ]
        },
        "CatTipoSanguineo": {
            "name": "CatTipoSanguineo",
            "values": [
                "AP",
                "AN",
                "BP",
                "BN",
                "ABP",
                "ABN",
                "OP",
                "ON",
                "UNKNOWN"
            ]
        },
        "CatDiscapacidad": {
            "name": "CatDiscapacidad",
            "values": [
                "N"
            ]
        },
        "CatEspecialidades": {
            "name": "CatEspecialidades",
            "values": [
                "ANATOMIA_PATOLOGICA",
                "IMAGENOLOGIA",
                "MEDICINA_PREVENTIVA",
                "ANESTESIOLOGIA",
                "MEDICINA_DEPORTIVA",
                "NEUMOLOGIA",
                "AUDIOLOGIA_OTONEURALGIA_FONIATRIA",
                "MEDICINA_REHABILITACION",
                "OFTALMOLOGIA",
                "CALIDAD_ATENCION_CLINICA",
                "URGENCIAS",
                "OTORRINOLARINGOLOGIA",
                "CIRUGIA_GENERAL",
                "MEDICINA_DEL_TRABAJO",
                "PATOLOGIA_CLINICA",
                "EPIDEMIOLOGIA",
                "MEDICINA_FAMILIAR",
                "PEDIATRIA",
                "GENETICA_MEDICA",
                "MEDICINA_INTERNA",
                "PSIQUIATRIA",
                "GERIATRIA",
                "MEDICINA_LEGAL",
                "RADIO_ONCOLOGIA",
                "GINAECOLOGIA_OBSTETRICIA",
                "MEDICINA_NUCLEAR_IMAGENOLOGIA_MOLECULAR",
                "TRAUMATOLOGIA_ORTOPEDIA"
            ]
        },
        "CatTipoPersonal": {
            "name": "CatTipoPersonal",
            "values": [
                "MEDICO_PASANTE_1",
                "MEDICO_GENERAL_2",
                "MEDICO_RESIDENTE_3",
                "MEDICO_ESPECIALISTA_4",
                "PASANTE_ENFERMERIA_5",
                "ENFERMERA_6",
                "PASANTE_NUTRICION_7",
                "NUTRIOLOGO_8",
                "HOMEOPATA_9",
                "MEDICO_TRADICIONAL_10",
                "TAPS_11",
                "PASANTE_PSICOLOGIA_15",
                "PSICOLOGO_16",
                "LICENCIADA_ENFERMERIA_OBSTETRICIA_20",
                "PARTERA_TECNICA_21",
                "PROMOTOR_SALUD_22",
                "MEDICO_ESPECIALISTA_SALUD_MENTAL_24",
                "LICENCIADO_GERANTOLOGIA_25",
                "OTROS_88"
            ]
        },
        "CatSexo": {
            "name": "CatSexo",
            "values": [
                "HOMBRE_1",
                "MUJER_2",
                "INTERSEXUAL_3"
            ]
        },
        "CatGenero": {
            "name": "CatGenero",
            "values": [
                "NO_ESPECIFICADO_0",
                "MASCULINO_1",
                "FEMENINO_2",
                "TRANSGENERO_3",
                "TRANSEXUAL_4",
                "TRASVESTI_5",
                "INTERSEXUAL_6",
                "OTRO_88"
            ]
        },
        "CatPais": {
            "name": "CatPais",
            "values": [
                "Afganistan",
                "Albania",
                "Alemania",
                "Andorra",
                "Angola",
                "Antigua_y_Barbuda",
                "Arabia_Saudita",
                "Argelia",
                "Argentina",
                "Armenia",
                "Australia",
                "Austria",
                "Azerbaiyan",
                "Bahamas",
                "Banglades",
                "Barbados",
                "Barein",
                "Belgica",
                "Belice",
                "Bielorrusia",
                "Benin",
                "Birmania",
                "Bolivia",
                "Bosnia_Herzegovina",
                "Botsuana",
                "Brasil",
                "Brunei",
                "Bulgaria",
                "Burkina_Faso",
                "Burundi",
                "Butan",
                "Cabo_Verde",
                "Camboya",
                "Camerun",
                "Canada",
                "Catar",
                "Republica_Centroafricana",
                "Chad",
                "Republica_Checa",
                "Chile",
                "China",
                "Chipre",
                "Colombia",
                "Comoras",
                "Republica_del_Congo",
                "Republica_Democratica_del_Congo",
                "Corea_del_Norte",
                "Corea_del_Sur",
                "Costa_de_Marfil",
                "Costa_Rica",
                "Croacia",
                "Cuba",
                "Dinamarca",
                "Dominica",
                "Republica_Dominicana",
                "Ecuador",
                "Egipto",
                "El",
                "Salvador",
                "Emiratos_Arabes_Unidos",
                "Eritrea",
                "Eslovaquia",
                "Eslovenia",
                "Espana",
                "Estados_Unidos",
                "Estonia",
                "Etiopia",
                "Filipinas",
                "Finlandia",
                "Fiyi",
                "Francia",
                "Gabon",
                "Gambia",
                "Georgia",
                "Ghana",
                "Granada",
                "Grecia",
                "Guatemala",
                "Guinea",
                "Guinea_Bisau",
                "Guinea_Ecuatorial",
                "Guyana",
                "Haiti",
                "Honduras",
                "Hungria",
                "India",
                "Indonesia",
                "Irak",
                "Iran",
                "Irlanda",
                "Islandia",
                "Israel",
                "Italia",
                "Jamaica",
                "Japon",
                "Jordania",
                "Kazajistan",
                "Kenia",
                "Kirguistan",
                "Kiribati",
                "Kuwait",
                "Laos",
                "Lesoto",
                "Letonia",
                "Libano",
                "Liberia",
                "Libia",
                "Liechtenstein",
                "Lituania",
                "Luxemburgo",
                "Macedonia_del_Norte",
                "Madagascar",
                "Malasia",
                "Malaui",
                "Maldivas",
                "Mali",
                "Malta",
                "Marruecos",
                "Islas_Marshall",
                "Mauricio",
                "Mauritania",
                "Mexico",
                "Micronesia",
                "Moldavia",
                "Monaco",
                "Mongolia",
                "Montenegro",
                "Mozambique",
                "Namibia",
                "Nauru",
                "Nepal",
                "Nicaragua",
                "Niger",
                "Nigeria",
                "Noruega",
                "Nueva_Zelanda",
                "Oman",
                "Paises_Bajos",
                "Pakistan",
                "Palaos",
                "Palestina",
                "Panama",
                "Papua_Nueva_Guinea",
                "Paraguay",
                "Peru",
                "Polonia",
                "Portugal",
                "Reino_Unido",
                "Ruanda",
                "Rumania",
                "Rusia",
                "Islas_Salomon",
                "Samoa",
                "San_Cristobal_y_Nieves",
                "San_Marino",
                "San_Vicente_y_las_Granadinas",
                "Santa_Lucia",
                "Santo_Tome_y_Principe",
                "Senegal",
                "Serbia",
                "Seychelles",
                "Sierra_Leona",
                "Singapur",
                "Siria",
                "Somalia",
                "Sri_Lanka",
                "Suazilandia_Esuatini",
                "Sudafrica",
                "Sudan",
                "Sudan_del_Sur",
                "Suecia",
                "Suiza",
                "Surinam",
                "Tailandia",
                "Tanzania",
                "Tayikistan",
                "Timor_Oriental",
                "Togo",
                "Tonga",
                "Trinidad_y_Tobago",
                "Tunez",
                "Turkmenistan",
                "Turquia",
                "Tuvalu",
                "Ucrania",
                "Uganda",
                "Uruguay",
                "Uzbekistan",
                "Vanuatu",
                "Vaticano",
                "Venezuela",
                "Vietnam",
                "Yemen",
                "Yibuti",
                "Zambia",
                "Zimbabue"
            ]
        },
        "CatTipoConsulta": {
            "name": "CatTipoConsulta",
            "values": [
                "PRIMERA",
                "SEGUIMIENTO"
            ]
        },
        "CatTipoDificultad": {
            "name": "CatTipoDificultad",
            "values": [
                "VER_1",
                "ESCUCHAR_2",
                "CAMINAR_3",
                "USAR_BRAZO_MANOS_4",
                "APRENDER_RECORDAR_5",
                "CUIDADO_PERSONAL_6",
                "HABLAR_COMUNICARSE_7",
                "EMOCIONAL_MENTAL_8",
                "NINGUNA_9"
            ]
        },
        "CatGradoDificultad": {
            "name": "CatGradoDificultad",
            "values": [
                "POCA_1",
                "MUCHA_2",
                "NO_PUEDE_3",
                "SIN_DIFICULTAD_9"
            ]
        },
        "CatOrigenDificultad": {
            "name": "CatOrigenDificultad",
            "values": [
                "ENFERMEDAD_1",
                "EDAD_AVANZADA_2",
                "ACIMIENTO_3",
                "ACCIDENTE_4",
                "VIOLENCIA_5",
                "OTRO_6",
                "SIN_DIFICULTAD_9"
            ]
        },
        "CatTelefono": {
            "name": "CatTelefono",
            "values": [
                "CELULAR",
                "FIJO",
                "TRABAJO",
                "OTRO"
            ]
        },
        "CatSiNo": {
            "name": "CatSiNo",
            "values": [
                "NO_0",
                "SI_1",
                "NO_RESPONDE_2",
                "NO_SABE_3"
            ]
        },
        "Maritalstatus": {
            "name": "Maritalstatus",
            "values": [
                "CASADO",
                "SOLTERO",
                "DIVORCIADO",
                "VIUDO",
                "UNION_LIBRE"
            ]
        }
    },
    "nonModels": {
        "Establecimiento": {
            "name": "Establecimiento",
            "fields": {
                "tipo": {
                    "name": "tipo",
                    "isArray": false,
                    "type": {
                        "enum": "CatEstablecimiento"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "nombre": {
                    "name": "nombre",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "domicilio": {
                    "name": "domicilio",
                    "isArray": false,
                    "type": {
                        "nonModel": "Domicilio"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "nombreDeLaInstitucion": {
                    "name": "nombreDeLaInstitucion",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "nombrePropietario": {
                    "name": "nombrePropietario",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "razonSocial": {
                    "name": "razonSocial",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "InformacionUsuario": {
            "name": "InformacionUsuario",
            "fields": {
                "cedula": {
                    "name": "cedula",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "CURP": {
                    "name": "CURP",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "nombre": {
                    "name": "nombre",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "primerApellido": {
                    "name": "primerApellido",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "segundoApellido": {
                    "name": "segundoApellido",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "especialidad": {
                    "name": "especialidad",
                    "isArray": false,
                    "type": {
                        "enum": "CatEspecialidades"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "subEpecialidad": {
                    "name": "subEpecialidad",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "InformacionFiscal": {
            "name": "InformacionFiscal",
            "fields": {
                "rfc": {
                    "name": "rfc",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "razonSocial": {
                    "name": "razonSocial",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "domicilioFiscal": {
                    "name": "domicilioFiscal",
                    "isArray": false,
                    "type": {
                        "nonModel": "Domicilio"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "AppConfiguration": {
            "name": "AppConfiguration",
            "fields": {
                "globalConfig": {
                    "name": "globalConfig",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "medicalFileConfig": {
                    "name": "medicalFileConfig",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Interrogatorio": {
            "name": "Interrogatorio",
            "fields": {
                "usuario": {
                    "name": "usuario",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "historiaFamiliar": {
                    "name": "historiaFamiliar",
                    "isArray": true,
                    "type": {
                        "nonModel": "HistoriaFamiliar"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "historiaPatologica": {
                    "name": "historiaPatologica",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "historiaNoPatologica": {
                    "name": "historiaNoPatologica",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "historiaGinecologica": {
                    "name": "historiaGinecologica",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "historiaDental": {
                    "name": "historiaDental",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "historiaPsicologica": {
                    "name": "historiaPsicologica",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ExamenFisico": {
            "name": "ExamenFisico",
            "fields": {
                "peso": {
                    "name": "peso",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "talla": {
                    "name": "talla",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "circunferenciaCintura": {
                    "name": "circunferenciaCintura",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "sistolica": {
                    "name": "sistolica",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "diastolica": {
                    "name": "diastolica",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "frecuenciaCardiaca": {
                    "name": "frecuenciaCardiaca",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "frecuenciaRespiratoria": {
                    "name": "frecuenciaRespiratoria",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "temperatura": {
                    "name": "temperatura",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "saturacionOxigeno": {
                    "name": "saturacionOxigeno",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "glucemia": {
                    "name": "glucemia",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "tipoMedicionGlicemia": {
                    "name": "tipoMedicionGlicemia",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "numeroDeTirasDeCntrol": {
                    "name": "numeroDeTirasDeCntrol",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "embarazadaSinDiabetes": {
                    "name": "embarazadaSinDiabetes",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "HistoriaFamiliar": {
            "name": "HistoriaFamiliar",
            "fields": {
                "quien": {
                    "name": "quien",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "que": {
                    "name": "que",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "masDetalles": {
                    "name": "masDetalles",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Receta": {
            "name": "Receta",
            "fields": {
                "idReceta": {
                    "name": "idReceta",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "usuario": {
                    "name": "usuario",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "medicamento": {
                    "name": "medicamento",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "unidadeDeMedida": {
                    "name": "unidadeDeMedida",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "dosis": {
                    "name": "dosis",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "frecuencia": {
                    "name": "frecuencia",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "viaDeAdministracion": {
                    "name": "viaDeAdministracion",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "fechaInicio": {
                    "name": "fechaInicio",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "fechaFin": {
                    "name": "fechaFin",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "indicacionesAdicionales": {
                    "name": "indicacionesAdicionales",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Telefono": {
            "name": "Telefono",
            "fields": {
                "number": {
                    "name": "number",
                    "isArray": false,
                    "type": "AWSPhone",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "CatTelefono"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "main": {
                    "name": "main",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Domicilio": {
            "name": "Domicilio",
            "fields": {
                "tipo": {
                    "name": "tipo",
                    "isArray": false,
                    "type": {
                        "enum": "CatVivienda"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "calle": {
                    "name": "calle",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "numeroExterior": {
                    "name": "numeroExterior",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "numeroInterior": {
                    "name": "numeroInterior",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "estado": {
                    "name": "estado",
                    "isArray": false,
                    "type": {
                        "enum": "CatEntidadFederativa"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "municipio": {
                    "name": "municipio",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "localidad": {
                    "name": "localidad",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "colonia": {
                    "name": "colonia",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "codigoPostal": {
                    "name": "codigoPostal",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "numeroDeTelefono": {
                    "name": "numeroDeTelefono",
                    "isArray": false,
                    "type": {
                        "nonModel": "Telefono"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        }
    },
    "codegenVersion": "3.4.4",
    "version": "1b0f48f82f6a95c729a4cf18bfed350f"
};