// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const CatEstablecimiento = {
  "CONSULTORIO": "CONSULTORIO"
};

const CatEntidadFederativa = {
  "AGS": "AGS",
  "BCN": "BCN",
  "BCS": "BCS",
  "CAM": "CAM",
  "CHP": "CHP",
  "CHI": "CHI",
  "DIF": "DIF",
  "COA": "COA",
  "COL": "COL",
  "DUR": "DUR",
  "GTO": "GTO",
  "GRO": "GRO",
  "HGO": "HGO",
  "JAL": "JAL",
  "MEX": "MEX",
  "MIC": "MIC",
  "MOR": "MOR",
  "NAY": "NAY",
  "NLE": "NLE",
  "OAX": "OAX",
  "PUE": "PUE",
  "QRO": "QRO",
  "ROO": "ROO",
  "SLP": "SLP",
  "SIN": "SIN",
  "SON": "SON",
  "TAB": "TAB",
  "TAM": "TAM",
  "TXL": "TXL",
  "VER": "VER",
  "YUC": "YUC",
  "ZAC": "ZAC"
};

const CatVivienda = {
  "PRIMARIO": "PRIMARIO",
  "TRABAJO": "TRABAJO",
  "REFERENCIA": "REFERENCIA"
};

const CatTipoSanguineo = {
  "AP": "AP",
  "AN": "AN",
  "BP": "BP",
  "BN": "BN",
  "ABP": "ABP",
  "ABN": "ABN",
  "OP": "OP",
  "ON": "ON",
  "UNKNOWN": "UNKNOWN"
};

const CatDiscapacidad = {
  "N": "N"
};

const CatEspecialidades = {
  "ANATOMIA_PATOLOGICA": "ANATOMIA_PATOLOGICA",
  "IMAGENOLOGIA": "IMAGENOLOGIA",
  "MEDICINA_PREVENTIVA": "MEDICINA_PREVENTIVA",
  "ANESTESIOLOGIA": "ANESTESIOLOGIA",
  "MEDICINA_DEPORTIVA": "MEDICINA_DEPORTIVA",
  "NEUMOLOGIA": "NEUMOLOGIA",
  "AUDIOLOGIA_OTONEURALGIA_FONIATRIA": "AUDIOLOGIA_OTONEURALGIA_FONIATRIA",
  "MEDICINA_REHABILITACION": "MEDICINA_REHABILITACION",
  "OFTALMOLOGIA": "OFTALMOLOGIA",
  "CALIDAD_ATENCION_CLINICA": "CALIDAD_ATENCION_CLINICA",
  "URGENCIAS": "URGENCIAS",
  "OTORRINOLARINGOLOGIA": "OTORRINOLARINGOLOGIA",
  "CIRUGIA_GENERAL": "CIRUGIA_GENERAL",
  "MEDICINA_DEL_TRABAJO": "MEDICINA_DEL_TRABAJO",
  "PATOLOGIA_CLINICA": "PATOLOGIA_CLINICA",
  "EPIDEMIOLOGIA": "EPIDEMIOLOGIA",
  "MEDICINA_FAMILIAR": "MEDICINA_FAMILIAR",
  "PEDIATRIA": "PEDIATRIA",
  "GENETICA_MEDICA": "GENETICA_MEDICA",
  "MEDICINA_INTERNA": "MEDICINA_INTERNA",
  "PSIQUIATRIA": "PSIQUIATRIA",
  "GERIATRIA": "GERIATRIA",
  "MEDICINA_LEGAL": "MEDICINA_LEGAL",
  "RADIO_ONCOLOGIA": "RADIO_ONCOLOGIA",
  "GINAECOLOGIA_OBSTETRICIA": "GINAECOLOGIA_OBSTETRICIA",
  "MEDICINA_NUCLEAR_IMAGENOLOGIA_MOLECULAR": "MEDICINA_NUCLEAR_IMAGENOLOGIA_MOLECULAR",
  "TRAUMATOLOGIA_ORTOPEDIA": "TRAUMATOLOGIA_ORTOPEDIA"
};

const CatTipoPersonal = {
  "MEDICO_PASANTE_1": "MEDICO_PASANTE_1",
  "MEDICO_GENERAL_2": "MEDICO_GENERAL_2",
  "MEDICO_RESIDENTE_3": "MEDICO_RESIDENTE_3",
  "MEDICO_ESPECIALISTA_4": "MEDICO_ESPECIALISTA_4",
  "PASANTE_ENFERMERIA_5": "PASANTE_ENFERMERIA_5",
  "ENFERMERA_6": "ENFERMERA_6",
  "PASANTE_NUTRICION_7": "PASANTE_NUTRICION_7",
  "NUTRIOLOGO_8": "NUTRIOLOGO_8",
  "HOMEOPATA_9": "HOMEOPATA_9",
  "MEDICO_TRADICIONAL_10": "MEDICO_TRADICIONAL_10",
  "TAPS_11": "TAPS_11",
  "PASANTE_PSICOLOGIA_15": "PASANTE_PSICOLOGIA_15",
  "PSICOLOGO_16": "PSICOLOGO_16",
  "LICENCIADA_ENFERMERIA_OBSTETRICIA_20": "LICENCIADA_ENFERMERIA_OBSTETRICIA_20",
  "PARTERA_TECNICA_21": "PARTERA_TECNICA_21",
  "PROMOTOR_SALUD_22": "PROMOTOR_SALUD_22",
  "MEDICO_ESPECIALISTA_SALUD_MENTAL_24": "MEDICO_ESPECIALISTA_SALUD_MENTAL_24",
  "LICENCIADO_GERANTOLOGIA_25": "LICENCIADO_GERANTOLOGIA_25",
  "OTROS_88": "OTROS_88"
};

const CatSexo = {
  "HOMBRE_1": "HOMBRE_1",
  "MUJER_2": "MUJER_2",
  "INTERSEXUAL_3": "INTERSEXUAL_3"
};

const CatGenero = {
  "NO_ESPECIFICADO_0": "NO_ESPECIFICADO_0",
  "MASCULINO_1": "MASCULINO_1",
  "FEMENINO_2": "FEMENINO_2",
  "TRANSGENERO_3": "TRANSGENERO_3",
  "TRANSEXUAL_4": "TRANSEXUAL_4",
  "TRASVESTI_5": "TRASVESTI_5",
  "INTERSEXUAL_6": "INTERSEXUAL_6",
  "OTRO_88": "OTRO_88"
};

const CatPais = {
  "AFGANISTAN": "Afganistan",
  "ALBANIA": "Albania",
  "ALEMANIA": "Alemania",
  "ANDORRA": "Andorra",
  "ANGOLA": "Angola",
  "ANTIGUA_Y_BARBUDA": "Antigua_y_Barbuda",
  "ARABIA_SAUDITA": "Arabia_Saudita",
  "ARGELIA": "Argelia",
  "ARGENTINA": "Argentina",
  "ARMENIA": "Armenia",
  "AUSTRALIA": "Australia",
  "AUSTRIA": "Austria",
  "AZERBAIYAN": "Azerbaiyan",
  "BAHAMAS": "Bahamas",
  "BANGLADES": "Banglades",
  "BARBADOS": "Barbados",
  "BAREIN": "Barein",
  "BELGICA": "Belgica",
  "BELICE": "Belice",
  "BIELORRUSIA": "Bielorrusia",
  "BENIN": "Benin",
  "BIRMANIA": "Birmania",
  "BOLIVIA": "Bolivia",
  "BOSNIA_HERZEGOVINA": "Bosnia_Herzegovina",
  "BOTSUANA": "Botsuana",
  "BRASIL": "Brasil",
  "BRUNEI": "Brunei",
  "BULGARIA": "Bulgaria",
  "BURKINA_FASO": "Burkina_Faso",
  "BURUNDI": "Burundi",
  "BUTAN": "Butan",
  "CABO_VERDE": "Cabo_Verde",
  "CAMBOYA": "Camboya",
  "CAMERUN": "Camerun",
  "CANADA": "Canada",
  "CATAR": "Catar",
  "REPUBLICA_CENTROAFRICANA": "Republica_Centroafricana",
  "CHAD": "Chad",
  "REPUBLICA_CHECA": "Republica_Checa",
  "CHILE": "Chile",
  "CHINA": "China",
  "CHIPRE": "Chipre",
  "COLOMBIA": "Colombia",
  "COMORAS": "Comoras",
  "REPUBLICA_DEL_CONGO": "Republica_del_Congo",
  "REPUBLICA_DEMOCRATICA_DEL_CONGO": "Republica_Democratica_del_Congo",
  "COREA_DEL_NORTE": "Corea_del_Norte",
  "COREA_DEL_SUR": "Corea_del_Sur",
  "COSTA_DE_MARFIL": "Costa_de_Marfil",
  "COSTA_RICA": "Costa_Rica",
  "CROACIA": "Croacia",
  "CUBA": "Cuba",
  "DINAMARCA": "Dinamarca",
  "DOMINICA": "Dominica",
  "REPUBLICA_DOMINICANA": "Republica_Dominicana",
  "ECUADOR": "Ecuador",
  "EGIPTO": "Egipto",
  "EL": "El",
  "SALVADOR": "Salvador",
  "EMIRATOS_ARABES_UNIDOS": "Emiratos_Arabes_Unidos",
  "ERITREA": "Eritrea",
  "ESLOVAQUIA": "Eslovaquia",
  "ESLOVENIA": "Eslovenia",
  "ESPANA": "Espana",
  "ESTADOS_UNIDOS": "Estados_Unidos",
  "ESTONIA": "Estonia",
  "ETIOPIA": "Etiopia",
  "FILIPINAS": "Filipinas",
  "FINLANDIA": "Finlandia",
  "FIYI": "Fiyi",
  "FRANCIA": "Francia",
  "GABON": "Gabon",
  "GAMBIA": "Gambia",
  "GEORGIA": "Georgia",
  "GHANA": "Ghana",
  "GRANADA": "Granada",
  "GRECIA": "Grecia",
  "GUATEMALA": "Guatemala",
  "GUINEA": "Guinea",
  "GUINEA_BISAU": "Guinea_Bisau",
  "GUINEA_ECUATORIAL": "Guinea_Ecuatorial",
  "GUYANA": "Guyana",
  "HAITI": "Haiti",
  "HONDURAS": "Honduras",
  "HUNGRIA": "Hungria",
  "INDIA": "India",
  "INDONESIA": "Indonesia",
  "IRAK": "Irak",
  "IRAN": "Iran",
  "IRLANDA": "Irlanda",
  "ISLANDIA": "Islandia",
  "ISRAEL": "Israel",
  "ITALIA": "Italia",
  "JAMAICA": "Jamaica",
  "JAPON": "Japon",
  "JORDANIA": "Jordania",
  "KAZAJISTAN": "Kazajistan",
  "KENIA": "Kenia",
  "KIRGUISTAN": "Kirguistan",
  "KIRIBATI": "Kiribati",
  "KUWAIT": "Kuwait",
  "LAOS": "Laos",
  "LESOTO": "Lesoto",
  "LETONIA": "Letonia",
  "LIBANO": "Libano",
  "LIBERIA": "Liberia",
  "LIBIA": "Libia",
  "LIECHTENSTEIN": "Liechtenstein",
  "LITUANIA": "Lituania",
  "LUXEMBURGO": "Luxemburgo",
  "MACEDONIA_DEL_NORTE": "Macedonia_del_Norte",
  "MADAGASCAR": "Madagascar",
  "MALASIA": "Malasia",
  "MALAUI": "Malaui",
  "MALDIVAS": "Maldivas",
  "MALI": "Mali",
  "MALTA": "Malta",
  "MARRUECOS": "Marruecos",
  "ISLAS_MARSHALL": "Islas_Marshall",
  "MAURICIO": "Mauricio",
  "MAURITANIA": "Mauritania",
  "MEXICO": "Mexico",
  "MICRONESIA": "Micronesia",
  "MOLDAVIA": "Moldavia",
  "MONACO": "Monaco",
  "MONGOLIA": "Mongolia",
  "MONTENEGRO": "Montenegro",
  "MOZAMBIQUE": "Mozambique",
  "NAMIBIA": "Namibia",
  "NAURU": "Nauru",
  "NEPAL": "Nepal",
  "NICARAGUA": "Nicaragua",
  "NIGER": "Niger",
  "NIGERIA": "Nigeria",
  "NORUEGA": "Noruega",
  "NUEVA_ZELANDA": "Nueva_Zelanda",
  "OMAN": "Oman",
  "PAISES_BAJOS": "Paises_Bajos",
  "PAKISTAN": "Pakistan",
  "PALAOS": "Palaos",
  "PALESTINA": "Palestina",
  "PANAMA": "Panama",
  "PAPUA_NUEVA_GUINEA": "Papua_Nueva_Guinea",
  "PARAGUAY": "Paraguay",
  "PERU": "Peru",
  "POLONIA": "Polonia",
  "PORTUGAL": "Portugal",
  "REINO_UNIDO": "Reino_Unido",
  "RUANDA": "Ruanda",
  "RUMANIA": "Rumania",
  "RUSIA": "Rusia",
  "ISLAS_SALOMON": "Islas_Salomon",
  "SAMOA": "Samoa",
  "SAN_CRISTOBAL_Y_NIEVES": "San_Cristobal_y_Nieves",
  "SAN_MARINO": "San_Marino",
  "SAN_VICENTE_Y_LAS_GRANADINAS": "San_Vicente_y_las_Granadinas",
  "SANTA_LUCIA": "Santa_Lucia",
  "SANTO_TOME_Y_PRINCIPE": "Santo_Tome_y_Principe",
  "SENEGAL": "Senegal",
  "SERBIA": "Serbia",
  "SEYCHELLES": "Seychelles",
  "SIERRA_LEONA": "Sierra_Leona",
  "SINGAPUR": "Singapur",
  "SIRIA": "Siria",
  "SOMALIA": "Somalia",
  "SRI_LANKA": "Sri_Lanka",
  "SUAZILANDIA_ESUATINI": "Suazilandia_Esuatini",
  "SUDAFRICA": "Sudafrica",
  "SUDAN": "Sudan",
  "SUDAN_DEL_SUR": "Sudan_del_Sur",
  "SUECIA": "Suecia",
  "SUIZA": "Suiza",
  "SURINAM": "Surinam",
  "TAILANDIA": "Tailandia",
  "TANZANIA": "Tanzania",
  "TAYIKISTAN": "Tayikistan",
  "TIMOR_ORIENTAL": "Timor_Oriental",
  "TOGO": "Togo",
  "TONGA": "Tonga",
  "TRINIDAD_Y_TOBAGO": "Trinidad_y_Tobago",
  "TUNEZ": "Tunez",
  "TURKMENISTAN": "Turkmenistan",
  "TURQUIA": "Turquia",
  "TUVALU": "Tuvalu",
  "UCRANIA": "Ucrania",
  "UGANDA": "Uganda",
  "URUGUAY": "Uruguay",
  "UZBEKISTAN": "Uzbekistan",
  "VANUATU": "Vanuatu",
  "VATICANO": "Vaticano",
  "VENEZUELA": "Venezuela",
  "VIETNAM": "Vietnam",
  "YEMEN": "Yemen",
  "YIBUTI": "Yibuti",
  "ZAMBIA": "Zambia",
  "ZIMBABUE": "Zimbabue"
};

const CatTipoConsulta = {
  "PRIMERA": "PRIMERA",
  "SEGUIMIENTO": "SEGUIMIENTO"
};

const CatTipoDificultad = {
  "VER_1": "VER_1",
  "ESCUCHAR_2": "ESCUCHAR_2",
  "CAMINAR_3": "CAMINAR_3",
  "USAR_BRAZO_MANOS_4": "USAR_BRAZO_MANOS_4",
  "APRENDER_RECORDAR_5": "APRENDER_RECORDAR_5",
  "CUIDADO_PERSONAL_6": "CUIDADO_PERSONAL_6",
  "HABLAR_COMUNICARSE_7": "HABLAR_COMUNICARSE_7",
  "EMOCIONAL_MENTAL_8": "EMOCIONAL_MENTAL_8",
  "NINGUNA_9": "NINGUNA_9"
};

const CatGradoDificultad = {
  "POCA_1": "POCA_1",
  "MUCHA_2": "MUCHA_2",
  "NO_PUEDE_3": "NO_PUEDE_3",
  "SIN_DIFICULTAD_9": "SIN_DIFICULTAD_9"
};

const CatOrigenDificultad = {
  "ENFERMEDAD_1": "ENFERMEDAD_1",
  "EDAD_AVANZADA_2": "EDAD_AVANZADA_2",
  "ACIMIENTO_3": "ACIMIENTO_3",
  "ACCIDENTE_4": "ACCIDENTE_4",
  "VIOLENCIA_5": "VIOLENCIA_5",
  "OTRO_6": "OTRO_6",
  "SIN_DIFICULTAD_9": "SIN_DIFICULTAD_9"
};

const CatTelefono = {
  "CELULAR": "CELULAR",
  "FIJO": "FIJO",
  "TRABAJO": "TRABAJO",
  "OTRO": "OTRO"
};

const CatSiNo = {
  "NO_0": "NO_0",
  "SI_1": "SI_1",
  "NO_RESPONDE_2": "NO_RESPONDE_2",
  "NO_SABE_3": "NO_SABE_3"
};

const Maritalstatus = {
  "CASADO": "CASADO",
  "SOLTERO": "SOLTERO",
  "DIVORCIADO": "DIVORCIADO",
  "VIUDO": "VIUDO",
  "UNION_LIBRE": "UNION_LIBRE"
};

const { ExpedienteClinico, Consulta, Paciente, User, Establecimiento, InformacionUsuario, InformacionFiscal, AppConfiguration, Interrogatorio, ExamenFisico, HistoriaFamiliar, Receta, Telefono, Domicilio } = initSchema(schema);

export {
  ExpedienteClinico,
  Consulta,
  Paciente,
  User,
  CatEstablecimiento,
  CatEntidadFederativa,
  CatVivienda,
  CatTipoSanguineo,
  CatDiscapacidad,
  CatEspecialidades,
  CatTipoPersonal,
  CatSexo,
  CatGenero,
  CatPais,
  CatTipoConsulta,
  CatTipoDificultad,
  CatGradoDificultad,
  CatOrigenDificultad,
  CatTelefono,
  CatSiNo,
  Maritalstatus,
  Establecimiento,
  InformacionUsuario,
  InformacionFiscal,
  AppConfiguration,
  Interrogatorio,
  ExamenFisico,
  HistoriaFamiliar,
  Receta,
  Telefono,
  Domicilio
};