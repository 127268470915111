import "./App.css";
import awsconfig from "./aws-exports";
import { Amplify, Hub } from "aws-amplify";
import { useEffect, useState } from "react";
import { RootState } from "./store/Store";
import { setCredentials, clearCredentials } from "./store/authSlice";
import { useAppDispatch } from "./store/Hooks";
import { useSelector } from "react-redux";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { verifySession } from "./services/auth/AuthService";
import { Box, CircularProgress, Container } from "@mui/material";
import { MainContainer } from "./components/dashboard/MainContainer";
import { rememberThisDevice } from "./types/User";
import SignIn from "./components/signin/SignIn";
import SignUp from "./components/singup/SingUp";
import ResetPassword from "./components/forgot-password/ResetPassword";
import MessageSnackBar from "./components/common-components/MessageSnackBar";
import { setMessageContent } from "./store/notificationSlice";
import useSubscribeToAuthMessages from "./services/auth/subscribeAuthService";
import { NewPatient } from "./components/patient/NewPatient";

Amplify.configure(awsconfig);

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const messageContent = useSubscribeToAuthMessages();
  useEffect(() => {
    if (messageContent) {
      dispatch(setMessageContent(messageContent));
    }
  }, [messageContent]);
  useEffect(() => {
    if (localStorage.getItem(rememberThisDevice) === "true") {
      verifySession()
        .then((user) => {
          dispatch(
            setCredentials({
              email: user.attributes.email,
              id: user.attributes.sub,
              emailVerified: user.attributes.email_verified,
            })
          );
        })
        .then(() => navigate(`/`))
        .catch((err) => console.log(err));
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          dispatch(
            setCredentials({
              email: data.email,
              id: data.sub,
              emailVerified: data.email_verified,
            })
          );
          navigate(`/`);
          break;
        case "signOut":
          dispatch(clearCredentials());
          navigate(`/signIn`);
          break;
      }
    });
  });

  return !isLoading ? (
    <>
      <MessageSnackBar />
      <Routes>
        <Route path="/signIn" element={<SignIn />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/forgotPassword" element={<ResetPassword />} />
        <Route path="*" element={<Navigate to="/signIn" replace />} />
        {user && (
          <>
            <Route
              path="/"
              element={<MainContainer title={""} children={<Box />} />}
            />
            <Route
              path="/newPatient"
              element={<MainContainer title={""} children={<NewPatient />} />}
            />
          </>
        )}
      </Routes>
    </>
  ) : (
    <CircularProgress />
  );
}
export default App;
