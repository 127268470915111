export const forgotPasswordTexts = {
  form: {
    formTitle: "Reinicializar su contraseña",
    introduceMail:
      "Favor de introducir la direccion de correo que asociada a su cuenta",
    resetPasswordButton: "Enviar codigo",
  },
  mailSent: {
    title: "Solicitud recibida",
    text: "Un codigo ha sido enviado a su correo, revise su buzon de entrada o su carpeta de correo no deseado ",
  },
};

export const resetPasswordTexts = {
	form: {
		formTitle: "Cambiar contraseña",
		backToLogin: "Regresar a iniciar secion",
		resetPasswordButton: "Reinicializar contraseña",
	},
	resetPasswordSuccess: {
		title: "Exito!",
		text: "Se reinicializo correctamente la contraseña",
	},

};
